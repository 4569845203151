html,
body {
  height: 100%;
  padding: 0px;
  margin: 0px;
}

.fullScreenCanvas {
  height: 100%;
  width: 100%;
  margin: 0px;
}
